import React, { useEffect, useState } from "react";
//----------css import --------------
import "../../styles/alliance-manager/allLicenseRequest.css";
//----------library import-----------
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
import { addDays } from "date-fns";
import format from "date-fns/format";

// ---------bootstrap properties-------
import { Card, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
//----------api handler-----------------
import AllianceManagerServices from "../../services/API/AllianceManager";
import axiosInstance from "../../services/axiosConfig";
// ---------components ---------
import AdminLayout from "../../components/layouts/adminLayout";
import LicenseRequestsFilter from "../../components/AllianceManager/LicenseRequests/LicenseRequestsFilter";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminPageContainer from "../../components/AdminPageContainer";
import moment from "moment";
import AliianceManagerLayout from "../../components/layouts/AliianceManagerLayout";
import { toast } from "react-toastify";
import AdminPageHeader from "../../components/AdminPageHeader";
import Loader from "../../components/Loader";
import LoaderContainer from "../../components/LoaderContainer";
import LicenseMasterServices from "../../services/API/LicenseMaster";
import { useQueries } from "react-query";
import FullScreenLoader from "../../components/FullScreenLoader";
import { useLocation, useNavigate } from "react-router";
const LicenseRequests = () => {
  const { state } = useLocation();

  const initialStateToBeFiltered = {
    fastTrackRequests: false,
    fastTrackStatus: false,
    requestType: [],
    vendor: state?.vendorName
    ? [state?.vendorName]
    : [],
    status: [],
    subStatus: [],
    assignedTo: [],
    state: [],
    cities: [],
    license: [],
    requestedBy: [],
    category: [],
    companyTypes: [],
    industryTypes: [],
    dueDateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    dateSubmit: false,
    requestedDateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    requestDateSubmit: false,

    // dueDateRange:""
  };
  const limit = 20;
  const [page, setPage] = useState(1);
  const [loading, setIsLoading] = useState(false);

  const [mainData, setMainData] = useState();
  const [allLicenseRequests, setAllLicenseRequests] = useState();
  const [dataToShow, setDataToShow] = useState([]);
  const [statusCards, setStatusCards] = useState([]);
  const [isSearchbarActive, setIsSearchbarActive] = useState(false);
  const [isSearchingActive, setIsSearchingActive] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [toBeFiltered, setToBeFiltered] = useState(initialStateToBeFiltered);
  const [activeClearFilterBtn, setActiveClearFilterBtn] = useState(false);
  const [csvLink, setCsvLink] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [subStatusList, setSubStatusList] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const [showReqDateRange, setShowReqDateRange] = useState(false);

  // ---------------below states are for status and cities filteration use ------------------

  useEffect(() => {
    if(state?.vendorId){
      handleApplyFilter()
    }else{
      fetchAllLicenseRequests();
    }
    fetchCardsInfo();
    exportData();
  }, []);
  const fetchAllLicenseRequests = async () => {
    setIsLoading(true);
    try {
      const res = await AllianceManagerServices.getAllLicenseRequests({
        page,
        limit,
      });
      if (res?.status === "success") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setMainData(res?.data);
        setAllLicenseRequests(res?.data);
        setDataToShow(res?.data?.data);
        setIsLoading(false);
      } else {
        setAllLicenseRequests({});
        setDataToShow([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  async function fetchCardsInfo() {


    try {
      const res = await AllianceManagerServices.totalLicenseRequests();
      if (res?.data) {
        setStatusCards([{ total: res?.data, title: res?.message }]);
      }
    } catch (error) {
    }
  }
  async function exportData() {
    const resp = await AllianceManagerServices.downloadReport();

    const headers = [
      { label: "S.No", key: "id" },
      { label: "SRN", key: "srn_name" },
      { label: "Type of Request", key: "license_type" },
      { label: "Location", key: "location" },
      // { label: "State", key: "state_name" },
      { label: "License", key: "license_name" },
      { label: "Assigned To", key: "assigned_to" },
      { label: "Due Date", key: "srn_duedate" },
      { label: "Requested Date", key: "srn_requesteddate" },
      { label: "Requested By", key: "requested_by" },
      { label: "Status", key: "srn_status" },
      { label: "Sub Status", key: "srn_substatus" },
      { label: "Vendor", key: "vendor_name" },
    ];
    setCsvLink({
      filename: "License-Requests.csv",
      headers: headers,
      data: resp ? resp : [],
    });
  }

  useEffect(() => {
    let timer;
    if (searchQuery.trim() && !applyFilter) {
      timer = setTimeout(async () => {
        setIsLoading(true);
        if (searchQuery.trim()) {
          try {
            const toBeShown = await AllianceManagerServices.search({
              searchQuery,
              paramsData: { page, limit },
            });

            if (toBeShown?.status === "success") {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              setAllLicenseRequests(toBeShown?.data);
              setDataToShow(toBeShown?.data?.data);
              setIsLoading(false);
            } else {
              setAllLicenseRequests({});
              setDataToShow([]);
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
          }
        }
      }, 1000);
    }
    if (!searchQuery.trim() && applyFilter) {
      filter();
    }
    if (!searchQuery.trim() && !applyFilter) {
      if(!state){
        fetchAllLicenseRequests();
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [page, searchQuery]);

  function handleApplyFilter() {
    if (
      toBeFiltered.fastTrackStatus ||
      toBeFiltered.requestType.length > 0 ||
      toBeFiltered?.vendor?.length > 0 ||
      toBeFiltered.status.length > 0 ||
      toBeFiltered.subStatus.length > 0 ||
      toBeFiltered.assignedTo.length > 0 ||
      toBeFiltered.state.length > 0 ||
      toBeFiltered.cities.length > 0 ||
      toBeFiltered.license.length > 0 ||
      toBeFiltered.requestedBy.length > 0 ||
      toBeFiltered.category.length > 0 ||
      toBeFiltered.companyTypes.length > 0 ||
      toBeFiltered.industryTypes.length > 0 ||
      toBeFiltered.dateSubmit ||
      toBeFiltered.requestDateSubmit
    ) {
      setApplyFilter(true);
      if (page !== 1) {
        setPage(1);
      } else {
        filter();
      }
      setActiveClearFilterBtn(true);
      setIsLoading(true);
      setSearchQuery("");
      setIsSearchingActive(false);
      // setApplyFilter(false)
    } else {
      // setOpenFilterBox(false);
      toast.error("Please fill out the fields");
      return;
    }
  }
  async function filter() {
    try {
      setIsLoading(true);
      const toBefiltered = {
        ...toBeFiltered,
        ["dueDateRange"]: `${format(
          toBeFiltered.dueDateRange[0].startDate,
          "dd-MM-yyyy"
        )} to ${format(toBeFiltered.dueDateRange[0].endDate, "dd-MM-yyyy")}`,
        ["requestedDateRange"]: `${format(
          toBeFiltered.requestedDateRange[0].startDate,
          "dd-MM-yyyy"
        )} to ${format(
          toBeFiltered.requestedDateRange[0].endDate,
          "dd-MM-yyyy"
        )}`,
      };

      // let srn_fasttrack = toBefiltered.fastTrackRequests ? 1 : 0;
      let fromDate = toBefiltered.dueDateRange
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let toDate = toBefiltered.dueDateRange
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let requested_from_date = toBefiltered.requestedDateRange
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let requested_to_date = toBefiltered.requestedDateRange
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      //  let withPage = firstTime ? 1 : page
      //  if(firstTime){
      //   setPage(1)
      //  }
      const toFilter = {
        page,
        limit,
      };
      if (toBeFiltered.fastTrackStatus && toBeFiltered.fastTrackRequests)
        toFilter["srn_fasttrack"] = 1;
      if (toBeFiltered.requestType.length > 0)
        toFilter["license-type"] = toBeFiltered.requestType;
      if (toBeFiltered.vendor.length > 0)
        toFilter["vendor_name"] = toBeFiltered.vendor;
      if (toBeFiltered.status.length > 0)
        toFilter["license-status"] = toBeFiltered.status;
      if (toBeFiltered.subStatus.length > 0)
        toFilter["license-substatus"] = toBeFiltered.subStatus;
      if (toBeFiltered.cities.length > 0)
        toFilter["assigned-city"] = toBeFiltered.cities;
      if (toBeFiltered.license.length > 0)
        toFilter["l-name"] = toBeFiltered.license;
      if (toBeFiltered.requestedBy.length > 0)
        toFilter["u-name"] = toBeFiltered.requestedBy;
      if (toBeFiltered.state.length > 0)
        toFilter["assigned-state"] = toBeFiltered.state;
      if (toBeFiltered.companyTypes.length > 0)
        toFilter["company-type"] = toBeFiltered.companyTypes;
      if (toBeFiltered.category.length > 0)
        toFilter["license-category"] = toBeFiltered.category;
      if (toBeFiltered.industryTypes.length > 0)
        toFilter["industry-type"] = toBeFiltered.industryTypes;
      if (toBeFiltered.dateSubmit) {
        toFilter["from_date"] = fromDate;
        toFilter["to_date"] = toDate;
      }
      if (toBeFiltered.requestDateSubmit) {
        toFilter["requested_from_date"] = requested_from_date;
        toFilter["requested_to_date"] = requested_to_date;
      }

      const response = await AllianceManagerServices.overallRequestListFilter(
        toFilter
      );
      if (response?.status === "success") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setAllLicenseRequests(response.data);
        setDataToShow(response.data.data);
        // setToBeFiltered(initialStateToBeFiltered);
        setOpenFilterBox(false);
        setIsLoading(false);
      } else {
        toast.error(response.message);
        setDataToShow([]);
        setAllLicenseRequests({})
        setIsLoading(false);
        setOpenFilterBox(false);
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
      setOpenFilterBox(false);
      setActiveClearFilterBtn(false);
    }
  }

  async function clearFilters() {
    // try {
    // const res = await AllianceManagerServices.getAllLicenseRequests({
    //   page,
    //   limit,
    // });
    // if (res.status === "success") {
    //   setAllLicenseRequests(res.data);
    //   setDataToShow(res?.data?.data);
    // } else {
    //   toast.error(res.message);
    // }
    if (page == 1) {
      fetchAllLicenseRequests();
    }

    setToBeFiltered({...initialStateToBeFiltered,vendor:[]});
    setApplyFilter(false);
    setActiveClearFilterBtn(false);
    setIsSearchingActive(false);
    setShowDuedateRange(false);
    setShowReqDateRange(false);
    setSearchQuery("");
    setPage(1);
    // } catch (error) {
    //   toast.error("No data found.");
    // }
  }

  // useEffect(() => {
  //   getFieldsData();
  // }, []);

  const results = useQueries([
    {
      queryKey: [`/api/vendor_list`, 1],
      queryFn: AllianceManagerServices.getVendorList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-status", 2],
      queryFn: AllianceManagerServices.getStatusList,
      staleTime: Infinity,
    },

    {
      queryKey: ["api/license/license-type", 3],
      queryFn: AllianceManagerServices.getLicenseTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/get_states", 4],
      queryFn: AllianceManagerServices.getStateList,
      staleTime: Infinity,
    },

    {
      queryKey: ["/api/license", 5],
      queryFn: AllianceManagerServices.getLicenseNameList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/user_list", 6],
      queryFn: AllianceManagerServices.getRequestedByList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-category", 7],
      queryFn: AllianceManagerServices.getCategoryList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-company", 8],
      queryFn: AllianceManagerServices.getCompanyTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-industry", 9],
      queryFn: AllianceManagerServices.getIndustryTypeList,
      staleTime: Infinity,
    },
  ]);

  useEffect(() => {
    if (toBeFiltered.state.length > 0) {
      const getCities = async () => {
        const stateListRes = await AllianceManagerServices.getStateList();

        const stateIds = toBeFiltered.state.map((state) => {
          return stateListRes?.data?.find((item) => item.state_name === state)
            .id;
        });
        try {
          const resp = await LicenseMasterServices.filterCity({
            state_id: stateIds,
            active: 1,
          });

          if (resp) {
            const cities = resp?.data?.map((item) => item.name);

            setCityList(cities);
          }
        } catch (e) {}
      };
      getCities();
    }
  }, [toBeFiltered.state]);

  useEffect(() => {
    if (toBeFiltered.status.length > 0) {
      const getSubStatus = async () => {
        const statusIds = toBeFiltered.status.map((status) => {
          return results[1]?.data?.data.find((item) => item.name === status)
            ?.id;
        });
        try {
          const resp = await AllianceManagerServices.filterSubStatusByStatus({
            license_id: statusIds,
          });
          if (resp) {
            const subStatus = resp?.data?.map((item) => item.name);
            setSubStatusList(subStatus);
          }
        } catch (e) {}
      };
      getSubStatus();
    }
  }, [toBeFiltered.status]);

  return (
    <>
      {loading && <FullScreenLoader show={loading} title="" />}
      <AliianceManagerLayout test="active">
        <AdminPageContainer>
          {openFilterBox && (
            <LicenseRequestsFilter
              openFilterBox={openFilterBox}
              setOpenFilterBox={setOpenFilterBox}
              toBeFiltered={toBeFiltered}
              setToBeFiltered={setToBeFiltered}
              handleApplyFilter={handleApplyFilter}
              loading={loading}
              initialStateToBeFiltered={initialStateToBeFiltered}
              vendorList={results[0]?.data?.data?.map((item) => item.name)}
              statusList={results[1]?.data?.data?.map((item) => item.name)}
              subStatusList={subStatusList}
              licenseTypeList={results[2]?.data?.data?.map((item) => item.name)}
              stateList={results[3]?.data?.data?.map((item) => item.name)}
              cityList={cityList}
              licenseNameList={results[4]?.data?.data?.map((item) => item.name)}
              requestedByList={results[5]?.data?.map((item) => item.name)}
              categoryList={results[6]?.data?.data?.map((item) => item.name)}
              companyTypeList={results[7]?.data?.data?.map((item) => item.name)}
              industryTypeList={results[8]?.data?.data?.map(
                (item) => item.name
              )}
              showDuedateRange={showDuedateRange}
              setShowDuedateRange={setShowDuedateRange}
              showReqDateRange={showReqDateRange}
              setShowReqDateRange={setShowReqDateRange}
            />
          )}

          <div
            className="py-2 px-1 h-100 lr-container"
            style={{ backgroundColor: "#f9f9f9" }}
          >
            <div id="main" role="main">
              <div id="content">
                <div>
                  <ol className="breadcrumb ">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
                    <li>License Requests</li>
                  </ol>
                </div>

                <div className="status-cards">
                  {statusCards?.length > 0 &&
                    statusCards.map((item, key) => (
                      // <div className="">
                      <Card
                        className="card card-sm card-shadow mb-3 text-center"
                        key={key}
                      >
                        <Card.Body className="pb-2">
                          <h2 className="status-card-count">{item.total}</h2>
                          <span className="status-card-title">
                            {item.title}
                          </span>
                        </Card.Body>
                      </Card>
                      // </div>
                    ))}
                </div>

                <div className="row">
                  <div className="card card-sm mb-3">
                    {/* {loading ? (
                  <div
                    style={{
                      paddingLeft: "0px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <div className="text-center" style={{ position: "fixed" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : ( */}
                    <>
                      <div className="card-body ">
                        <div className="d-flex justify-content-between">
                          <h3>License Requests</h3>
                          <div className="d-flex justify-content-end align-items-center gap-2 pb-2 mt-5">
                            {activeClearFilterBtn && (
                              <div
                                style={{ cursor: "pointer" }}
                                className="button_new"
                              >
                                <a
                                  onClick={() => {
                                    setIsSearchbarActive(false);
                                    setDataToShow(allLicenseRequests.data);
                                    clearFilters();
                                  }}
                                  target="blank"
                                  style={{ padding: "5px 12px !important" }}
                                >
                                  Clear Filters
                                </a>
                              </div>
                            )}
                            <div className="button_new">
                              <a>
                                {isSearchbarActive ? (
                                  <input
                                    type="search"
                                    placeholder="search "
                                    value={searchQuery}
                                    onChange={(e) => {
                                      setSearchQuery(e.target.value);
                                      if (e.target.value) {
                                        setApplyFilter(false);

                                        setPage(1);
                                        setActiveClearFilterBtn(true);
                                        setIsSearchingActive(true);
                                      }
                                      // else setActiveClearFilterBtn(false);
                                    }}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      height: "16px",
                                    }}
                                    title="Search"
                                  />
                                ) : (
                                  <i
                                    className="fa fa-search requests-icons"
                                    title="Search"
                                    aria-hidden="true"
                                    //title="Search"
                                    onClick={() => {
                                      setIsSearchbarActive(true);
                                    }}
                                  ></i>
                                )}

                                {/* {isSearchbarActive ? (
                           <i
                             className="fa fa-times requests-icons"
                             aria-hidden="true"
                             onClick={() => {
                               setIsSearchbarActive(false);
                               setDataToShow(allLicenseRequests.data);
                             }}
                           ></i>
                         ) : (
                           <i
                             className="fa fa-search requests-icons"
                             aria-hidden="true"
                             onClick={() => setIsSearchbarActive(true)}
                           ></i>
                         )} */}
                              </a>
                            </div>
                            <div className="button_new">
                              <a
                                onClick={() => {
                                  setOpenFilterBox(true);
                                }}
                                target="blank"
                                // style={{ marginRight: "45px" }}
                              >
                                <i
                                  className="fa fa-filter fa-fw requests-icons"
                                  title="Show Filters"
                                ></i>
                              </a>
                            </div>
                            {Object.keys(csvLink).length > 0 && (
                              <div className="button_new">
                                <CSVLink
                                  {...csvLink}
                                  onClick={() => {
                                    if (csvLink)
                                      toast.success(
                                        "Overall License Requests Downloaded Successfully"
                                      );
                                  }}
                                >
                                  <i
                                    className="fa fa-download requests-icons"
                                    aria-hidden="true"
                                    title="Download"
                                    style={{ color: "black" }}
                                  ></i>
                                </CSVLink>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* ---------Table Starts Here-------- */}

                        <div className="table-responsive">
                          <table
                            className="table table-bordered table-hover dataTable no-footer"
                            id="myTable1"
                            style={{
                              tableLayout: "fixed",
                            }}
                          >
                            <thead>
                              <tr
                                role="row"
                                style={{
                                  fontWeight: "500",
                                  background: "#eee",
                                }}
                              >
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="SR No.: activate to sort column ascending"
                                  style={{ width: "34px" }}
                                >
                                  S. No.
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="SR No.: activate to sort column ascending"
                                  style={{ width: "120px" }}
                                >
                                  SRN
                                </th>

                                <th
                                  className=" text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Type of Request: activate to sort column ascending"
                                  style={{ width: "50px" }}
                                >
                                  Type of Request
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  style={{ width: "55px" }}
                                >
                                  Location
                                </th>
                                {/* <th
                                className="text-center align-middle py-2"
                                // className="sorting"
                                tabIndex="0"
                                aria-controls="myTable1"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Location: activate to sort column ascending"
                              // style={{ width: "63px" }}
                              >
                                City
                              </th> */}

                                {/* <th className="sorting" tabIndex="0" aria-controls="myTable1" rowSpan="1" colSpan="1" aria-label="State: activate to sort column ascending" style={{width:"52px"}}>State</th> */}
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="License Type: activate to sort column ascending"
                                  style={{ width: "50px" }}
                                >
                                  License
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="License Type: activate to sort column ascending"
                                  style={{ width: "60px" }}
                                >
                                  Assigned To
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Category: activate to sort column ascending"
                                  style={{ width: "50px" }}
                                >
                                  Due Date
                                </th>
                                <th
                                  className="text-center align-middle"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Requested Date: activate to sort column ascending"
                                  style={{ width: "63px" }}
                                >
                                  Requested Date
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Requested By: activate to sort column ascending"
                                  style={{ width: "65px" }}
                                >
                                  Requested By
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  style={{ width: "50px" }}
                                >
                                  Status
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  style={{ width: "50px" }}
                                >
                                  Sub Status
                                </th>
                                <th
                                  className="text-center align-middle py-2"
                                  // className="sorting"
                                  tabIndex="0"
                                  aria-controls="myTable1"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  style={{ width: "50px" }}
                                >
                                  Vendor
                                </th>
                              </tr>
                            </thead>

                            <tbody style={{ fontSize: "12px" }}>
                              {dataToShow?.length > 0 &&
                                dataToShow.map((item, key) => (
                                  <tr role="row" className="odd" key={key}>
                                    <td className="text-start align-middle">
                                      {/* {limit * (page - 1) + key + 1} */}
                                      {allLicenseRequests.from + key}
                                    </td>
                                    <td className="sorting_1 text-start align-middle">
                                      <Link
                                        to={`/alliance-manager/overall-license-requests/license-request-details/${item.id}`}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          {item?.srn_fasttrack == "1" && (
                                            <i
                                              className="fa-solid fa-flag"
                                              style={{ color: "red" }}
                                            ></i>
                                          )}

                                          <span>{item?.srn_name || ""}</span>
                                        </div>
                                      </Link>
                                    </td>

                                    <td className="text-start align-middle">
                                      {item?.license_type || ""}
                                    </td>
                                    <td className="text-start align-middle">
                                      {item?.city_name || ""},{" "}
                                      {item?.state_name || ""}
                                    </td>
                                    {/* <td className="text-start align-middle">
                                    {item?.city_name || ""}
                                  </td> */}
                                    <td className="text-start align-middle">
                                      {item?.license_name || ""}
                                      {/* <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                        margin: "0px 0px",
                                        padding: "0px 0px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle fa-fw"
                                        aria-hidden="true"
                                        style={{ color: "#61b9ff" }}
                                        title="Online"
                                      ></i>
                                      <span>{item?.license_name || ""}</span>
                                    </div> */}
                                    </td>
                                    <td className="text-start align-middle">
                                      {item?.executive_name ? (
                                        <>
                                          <i
                                            className="fa-solid fa-user"
                                            style={{ color: "#c6538c" }}
                                          ></i>{" "}
                                          {item.executive_name}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.field_executive_name ? (
                                        <>
                                          , <br />{" "}
                                          <i
                                            className="fa-solid fa-user"
                                            style={{ color: "#c6538c" }}
                                          ></i>{" "}
                                          {item.field_executive_name}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td className="text-start align-middle">
                                      {item?.srn_duedate
                                        ? moment(item?.srn_duedate).format(
                                            "DD-MM-YY hh:mm A"
                                          )
                                        : ""}
                                    </td>
                                    <td
                                      className="text-start align-middle"
                                      // style={{
                                      //   whiteSpace: "pre-wrap",
                                      //   overflowWrap: "break-word",
                                      // }}
                                    >
                                      {" "}
                                      {item?.srn_requesteddate
                                        ? moment(
                                            item?.srn_requesteddate
                                          ).format("DD-MM-YY hh:mm A")
                                        : ""}{" "}
                                    </td>
                                    <td className="text-start align-middle">
                                      {" "}
                                      {item?.requested_by || ""}{" "}
                                    </td>
                                    <td className="text-start align-middle">
                                      {item?.srn_status || ""}
                                    </td>
                                    <td className="text-start align-middle">
                                      {item?.srn_substatus || ""}
                                    </td>
                                    <td className="text-start align-middle">
                                      {item?.vendor_name || ""}
                                    </td>
                                    {/* {item?.company_name || "--"} */}
                                    {/* <!--<td>3</td>--> */}
                                    {/* <!--<td></td>--> */}
                                  </tr>
                                ))}
                              {!dataToShow?.length > 0 && !loading && (
                                <tr>
                                  <td
                                    colSpan={12}
                                    className="text-center align-middle"
                                  >
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                         
                          <div className="d-flex justify-content-end mr-2">
                            {allLicenseRequests?.total > limit && (
                              <Pagination
                                activePage={allLicenseRequests?.current_page}
                                itemsCountPerPage={Number(
                                  allLicenseRequests?.per_page
                                )}
                                totalItemsCount={allLicenseRequests?.total}
                                pageRangeDisplayed={5}
                                onChange={(number) => {
                                  setPage(number);
                                }}
                              />
                            )}
                          </div>
                        </div>

                        {/* ---------Table Ends Here-------- */}
                      </div>
                    </>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AdminPageContainer>
      </AliianceManagerLayout>
    </>
  );
};

export default LicenseRequests;
