import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const Editor = ({ data, setData, handleSave }) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={data ? data : ""}
        config={{
          // Specify the toolbar settings
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "undo",
              "redo",
              "|",
              "link",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "blockQuote",
              // "insertTable",
              "|",
              "alignment",
              "|",
              "fontBackgroundColor",
              "fontColor",
              "|",
              "outdent",
              "indent",
              "|",
              "horizontalLine",
              "|",
            ],
          },
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
        }}
        onChange={(event, editor) => {
          const editorData = editor.getData();
          setData(editorData);
        }}
        onBlur={(event, editor) => {
        }}
        onFocus={(event, editor) => {
        }}
      />

      <section className="pull-right m-4" onClick={handleSave}>
        <a className="btn button_new_success p-3">Save</a>
      </section>
    </>
  );
};

export default Editor;